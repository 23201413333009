import React from 'react';

import { Layout } from '../components/layout';
import { SEO } from '../components/seo';
import { Content } from '../components/content';

export default function() {
  const title = 'Implantes dentales';

  return (
    <Layout>
      <SEO title={title} />
      <h1>{title}</h1>
      <Content>
        <p>
          Un implante dental es un elemento biocompatible, está fabricado con titanio y nuestro cuerpo lo acepta sin
          provocar una reacción adversa. Su función es actuar como raíz artificial para servir de sustento estable para
          la colocación segura, consistente y firme de un elemento protético que suplirá el o los dientes perdidos.
          Existe una gran variedad de implantes dentales y de acuerdo al diagnóstico se elige el ideal para cada caso
          clínico en particular.
        </p>
        <p>
          Los implantes se integran a la cavidad bucal de modo estético, funcional, eficaz y perdurable; restituyendo la
          anatomía, la función y el confort, permitiendo al paciente masticar, sonreír y hablar con absoluta
          naturalidad.
        </p>
        <p>
          La cirugía de instalación de un implante puede demorar entre 30 y 60 minutos. Finalizada la misma se inicia el
          período de oseointegración o unión del implante al hueso que dura aproximadamente 3 meses. A partir de allí
          comienza la fase protética o de rehabilitación de las piezas faltantes.
        </p>
        <p>
          En nuestro laboratorio dental confeccionamos los elementos protéticos bajo supervisión de la dirección técnica
          de la Institución. Las prótesis se darán por concluidas en el momento en que se adapten exactamente a las
          necesidades funcionales y estéticas de cada paciente, garantizándose así la culminación exitosa del proceso.
        </p>

        <h2>Preguntas frecuentes</h2>
        <h3>¿Los implantes dentales son exitosos?</h3>
        <p>Si. Hoy entre un 97% y 98% de los casos son exitosos.</p>
        <h3>¿Es dolorosa la colocación de un implante?</h3>
        <p>
          No, en absoluto. Si bien los implantes dentales requieren cirugías muy delicadas, estas suelen realizarse con
          anestesia local, y resultan ser menos traumáticos y dolorosos que una simple extracción dental.
        </p>
        <p>
          Utilizando la medicación apropiada y cumpliendo con las indicaciones post quirúrgicas se anulan las
          posibilidades de inflamación y dolor postoperatorios.
        </p>
        <h3>¿La anestesia odontológica para cirugías de implantes dentales es diferente?</h3>
        <p>
          No. Es la misma anestesia que se utiliza para realizar cualquier tratamiento dental como caries o
          extracciones.
        </p>
        <h3>¿Hay posibilidades de que el implante no se integre al hueso?</h3>
        <p>
          Utilizando implantes odontológicos de óptima calidad y técnicas profesionales apropiadas, se reducen al mínimo
          las posibilidades de falta de integración del implante.
        </p>
        <h3>¿Cuánto tiempo dura un implante dental?</h3>
        <p>
          En la práctica médica es difícil asegurar la duración de un tratamiento. Sin embargo hay casos testigos de
          implantes colocados hace más de 30 años que aún permanecen funcionales. Un implante puede durar toda la vida
          siempre y cuando el paciente cumpla con todas las normas de higiene y visitas periódicas al profesional en
          form.
        </p>
        <h3>¿Existen distintos tipos de implantes dentales?</h3>
        <p>
          Sí, por supuesto, hay diversos tipos de implantes que serán utilizados de acuerdo a cada caso clínico en
          particular. Existen distintos tipos de hueso según su densidad y cada uno de ellos requiere una solución con
          un determinado tipo de implante. Estas diferencias entre los implantes tienen que ver con su diseño geométrico
          y con el tratamiento químico que recibe la superficie del Titanio durante su fabricación.
        </p>
        <p>
          Elegir el tipo de implante más adecuado para el caso clínico resulta vital para el éxito de todo el proceso.
          Es aquí en donde el diagnóstico a través de radiografias y tomografias axiales computadas toma suma
          importancia.{' '}
        </p>
      </Content>
    </Layout>
  );
}
